import React, { useState } from 'react';
import FooterPart from '../FooterPart';
import HeadPart from '../HeadPart';

function Our_Partners() {
    return (
      <>
        <HeadPart />
        <header
          id="header"
          className="hc-cmp-header hc-component header-imagePass"
          style={{
            backgroundImage:
              "url(../wp-content/uploads/2019/06/hd-wide-6-1920x500.jpg)",
          }}
        >
          <div className="container header-imagePass-heading">
            <h1>Acenna Partners</h1>
          </div>
        </header>
        <main>
          <div className="container getaQuoteRegister">
            <div className="blog logos-list">
              <div className="row">
                <div className="col-md-3 col-xs-6">
                  <div className="blog-inner">
                    <div className="blog-img img-source">
                      <a href="https://www.bankofbaroda.in/" target="_blank">
                        <img
                          className="img-responsive"
                          src="wp-content/uploads/logo/bob.png"
                        />
                      </a>
                    </div>
                    <div className="blog-content logo-content">
                      <h3>
                        <a href="https://www.bankofbaroda.in/" target="_blank">
                          Bank of Baroda
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-xs-6">
                  <div className="blog-inner">
                    <div className="blog-img img-source">
                      <a href="https://www.icicibank.com/" target="_blank">
                        <img
                          className="img-responsive"
                          src="wp-content/uploads/logo/icici.png"
                        />
                      </a>
                    </div>
                    <div className="blog-content logo-content">
                      <h3>
                        <a href="https://www.icicibank.com/" target="_blank">
                          ICICI Bank
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-xs-6">
                  <div className="blog-inner">
                    <div className="blog-img img-source">
                      <a href="https://www.yesbank.in/" target="_blank">
                        <img
                          className="img-responsive"
                          src="wp-content/uploads/logo/Yes_Bank_logo.png"
                        />
                      </a>
                    </div>
                    <div className="blog-content logo-content">
                      <h3>
                        <a href="https://www.yesbank.in/" target="_blank">
                          Yes Bank
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-xs-6">
                  <div className="blog-inner">
                    <div className="blog-img img-source">
                      <a href="https://www.emiratesnbd.com/en" target="_blank">
                        <img
                          className="img-responsive"
                          src="wp-content/uploads/logo/emiratesnbd.png"
                        />
                      </a>
                    </div>
                    <div className="blog-content logo-content">
                      <h3>
                        <a
                          href="https://www.emiratesnbd.com/en"
                          target="_blank"
                        >
                          Emirates NBD
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-xs-6">
                  <div className="blog-inner">
                    <div className="blog-img img-source">
                      <a
                        href="https://www.southindianbank.com/"
                        target="_blank"
                      >
                        <img
                          className="img-responsive"
                          src="wp-content/uploads/logo/southindianbank1.png"
                        />
                      </a>
                    </div>
                    <div className="blog-content logo-content">
                      <h3>
                        <a
                          href="https://www.southindianbank.com/"
                          target="_blank"
                        >
                          South Indian Bank
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-3 col-xs-6">
                  <div className="blog-inner">
                    <div className="blog-img img-source">
                      <a href="https://www.hdbfs.com/" target="_blank">
                        <img
                          className="img-responsive"
                          src="wp-content/uploads/logo/hdfc.png"
                        />
                      </a>
                    </div>
                    <div className="blog-content logo-content">
                      <h3>
                        <a href="https://www.hdbfs.com/" target="_blank">
                          HDB Financial Services
                        </a>
                      </h3>
                    </div>
                  </div>
                </div> */}

                <div className="col-md-3 col-xs-6">
                  <div className="blog-inner">
                    <div className="blog-img img-source">
                      <a href="https://moneyview.in/" target="_blank">
                        <img
                          className="img-responsive"
                          src="wp-content/uploads/logo/moneyView.png"
                        />
                      </a>
                    </div>
                    <div className="blog-content logo-content">
                      <h3>
                        <a href="https://moneyview.in/" target="_blank">
                          MoneyView
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-xs-6">
                  <div className="blog-inner">
                    <div className="blog-img img-source">
                      <a href="https://www.mpokket.in/" target="_blank">
                        <img
                          className="img-responsive"
                          src="wp-content/uploads/logo/mpokket.png"
                        />
                      </a>
                    </div>
                    <div className="blog-content logo-content">
                      <h3>
                        <a href="https://www.mpokket.in/" target="_blank">
                          mPokket
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-xs-6">
                  <div className="blog-inner">
                    <div className="blog-img img-source">
                      <a href="https://www.sliceit.com/" target="_blank">
                        <img
                          className="img-responsive"
                          src="wp-content/uploads/logo/slice.png"
                        />
                      </a>
                    </div>
                    <div className="blog-content logo-content">
                      <h3>
                        <a href="https://www.sliceit.com/" target="_blank">
                          Slice
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-3 col-xs-6">
                  <div className="blog-inner">
                    <div className="blog-img img-source">
                      <a href="https://getsimpl.com/" target="_blank">
                        <img
                          className="img-responsive"
                          src="wp-content/uploads/logo/smpl.png"
                        />
                      </a>
                    </div>
                    <div className="blog-content logo-content">
                      <h3>
                        <a href="https://getsimpl.com/" target="_blank">
                          Simpl
                        </a>
                      </h3>
                    </div>
                  </div>
                </div> */}

                <div className="col-md-3 col-xs-6">
                  <div className="blog-inner">
                    <h4>Digital Lending Partner</h4>
                    <div className="blog-img img-source1">
                      <a href="https://www.truecredits.in/" target="_blank">
                        <img
                          className="img-responsive"
                          src="wp-content/uploads/logo/download.png"
                        />
                      </a>
                      <div>
                      <h3>
                        <a href="https://www.truecredits.in/" target="_blank">
                        True Credits Private Limited
                        </a>
                      </h3>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-3 col-xs-6">
                  <div className="blog-inner">
                    <div className="blog-img img-source">
                      <a href="https://www.moneytap.com/" target="_blank">
                        <img
                          className="img-responsive"
                          src="wp-content/uploads/logo/moneytap.png"
                        />
                      </a>
                    </div>
                    <div className="blog-content logo-content">
                      <h3>
                        <a href="https://www.moneytap.com/" target="_blank">
                          moneyTap
                        </a>
                      </h3>
                    </div>
                  </div>
                </div> */}

                
                <div className="col-md-3 col-xs-6">
                  <div className="blog-inner">
                    <div className="blog-img img-source">
                      <a href="https://stucred.com/" target="_blank">
                        <img
                          className="img-responsive"
                          src="wp-content/uploads/logo/stucred.jpg"
                        />
                      </a>
                    </div>
                    <div className="blog-content logo-content">
                      <h3>
                        <a href="https://stucred.com/" target="_blank">
                        StuCred
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 col-xs-6">
                  <div className="blog-inner">
                    <div className="blog-img img-source">
                      <a href="https://fatakpay.com/" target="_blank">
                        <img
                          className="img-responsive"
                          src="wp-content/uploads/logo/fatakpayLogo.jpg"
                        />
                      </a>
                    </div>
                    <div className="blog-content logo-content">
                      <h3>
                        <a href="https://fatakpay.com/" target="_blank">
                        FatakPay
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 col-xs-6">
                  <div className="blog-inner">
                    <div className="blog-img img-source">
                      <a href="https://www.kissht.com/" target="_blank">
                        <img
                          className="img-responsive"
                          src="wp-content/uploads/logo/kissht.jpg"
                        />
                      </a>
                    </div>
                    <div className="blog-content logo-content">
                      <h3>
                        <a href="https://www.kissht.com/" target="_blank">
                        Kissht
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 col-xs-6">
                  <div className="blog-inner">
                    <div className="blog-img img-source">
                      <a href="https://www.indusind.com/" target="_blank">
                        <img
                          className="img-responsive"
                          src="wp-content/uploads/logo/indusind-bank1.png"
                        />
                      </a>
                    </div>
                    <div className="blog-content logo-content">
                      <h3>
                        <a href="https://www.indusind.com/" target="_blank">
                        IndusInd Bank
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 col-xs-6">
                  <div className="blog-inner">
                    <div className="blog-img img-source">
                      <a href="https://trustpaisa.com/" target="_blank">
                        <img
                          className="img-responsive"
                          src="wp-content/uploads/logo/TrustPaisa.jpg"
                        />
                      </a>
                    </div>
                    <div className="blog-content logo-content">
                      <h3>
                        <a href="https://trustpaisa.com/" target="_blank">
                        TrustPaisa
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 col-xs-6">
                  <div className="blog-inner">
                    <div className="blog-img img-source">
                      <a href="https://bhanixfinance.com/" target="_blank">
                        <img
                          className="img-responsive"
                          src="wp-content/uploads/logo/bhanix-finance.png"
                        />
                      </a>
                    </div>
                    <div className="blog-content logo-content">
                      <h3>
                        <a href="https://bhanixfinance.com/" target="_blank">
                          bhanixFinance
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 col-xs-6">
                  <div className="blog-inner">
                    <div className="blog-img img-source">
                      <a href="https://react.krazybee.com/" target="_blank">
                        <img
                          className="img-responsive"
                          src="wp-content/uploads/logo/krazybee.png"
                        />
                      </a>
                    </div>
                    <div className="blog-content logo-content">
                      <h3>
                        <a href="https://react.krazybee.com/" target="_blank">
                          KrazyBee
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-xs-6">
                  <div className="blog-inner">
                    <div className="blog-img img-source">
                      <a href="https://www.dmifinance.in/" target="_blank">
                        <img
                          className="img-responsive"
                          src="wp-content/uploads/logo/dmi.png"
                        />
                      </a>
                    </div>
                    <div className="blog-content logo-content">
                      <h3>
                        <a href="https://www.dmifinance.in/" target="_blank">
                          DMI Finance
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 col-xs-6">
                  <div className="blog-inner">
                    <div className="blog-img img-source">
                      <a href="https://www.cashe.co.in/" target="_blank">
                        <img
                          className="img-responsive"
                          src="wp-content/uploads/logo/cashe.png"
                        />
                      </a>
                    </div>
                    <div className="blog-content logo-content">
                      <h3>
                        <a href="https://www.cashe.co.in/" target="_blank">
                          Cashe
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 col-xs-6">
                  <div className="blog-inner">
                    <div className="blog-img img-source">
                      <a href="https://www.lendingkart.com/" target="_blank">
                        <img
                          className="img-responsive"
                          src="wp-content/uploads/logo/lendingkart.png"
                        />
                      </a>
                    </div>
                    <div className="blog-content logo-content">
                      <h3>
                        <a href="https://www.lendingkart.com/" target="_blank">
                        Lendingkart
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </main>
        <FooterPart />
      </>
    );
}

export default Our_Partners;